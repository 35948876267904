import React, { useState } from 'react';
import ParticipantInfo from '../ParticipantInfo/ParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import { Participant as IParticipant } from 'twilio-video';
import { getUser } from '../../OIKOS/OIKOS';

interface ParticipantProps {
  participant: IParticipant;
  videoOnly?: boolean;
  enableScreenShare?: boolean;
  onClick?: () => void;
  isSelected?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
  isDominantSpeaker?: boolean;
}

export function Participant({
  participant,
  videoOnly,
  enableScreenShare,
  onClick,
  isSelected,
  isLocalParticipant,
  hideParticipant,
  isDominantSpeaker,
}: ParticipantProps) {
  const [user, setUser] = useState(null);
  const [loaded, setLoaded] = useState<boolean>(false);

  if (user == null) {
    getUser(participant.identity).then(user => {
      setUser(user);
      setLoaded(true);
    });
  }

  return (
    <>
      {loaded && (
        <ParticipantInfo
          user={user}
          participant={participant}
          onClick={onClick}
          isSelected={isSelected}
          isLocalParticipant={isLocalParticipant}
          hideParticipant={hideParticipant}
          isDominantSpeaker={isDominantSpeaker}
        >
          <ParticipantTracks
            participant={participant}
            videoOnly={videoOnly}
            enableScreenShare={enableScreenShare}
            isLocalParticipant={isLocalParticipant}
          />
        </ParticipantInfo>
      )}
    </>
  );
}

export default React.memo(Participant);
