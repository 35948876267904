import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { getUser } from '../../OIKOS/OIKOS';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

export default function PreJoinScreens() {
  const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName } = useParams<{ URLRoomName?: string }>();
  const { URLParticipantUserID } = useParams<{ URLParticipantUserID?: string }>();
  // const [userName, setUserName] = useState<string>('');
  const [oikosUser, setOikosUser] = useState<any>();
  const [step, setStep] = useState(Steps.roomNameStep);

  const [userID, setUserID] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [educator, setEducator] = useState<any>();
  const [learner, setLearner] = useState<any>();
  const [counterpart, setCounterpart] = useState<any>();
  const [roomDisplayName, setRoomDisplayName] = useState<string>('');
  const [mediaError, setMediaError] = useState<Error>();

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (URLRoomName) {
      const educatorID = URLRoomName.substring(0, 36);
      const learnerID = URLRoomName.substring(36, 73);
      getUser(educatorID).then(edu => {
        getUser(learnerID).then(lea => {
          setEducator(edu);
          setLearner(lea);
          setRoomName(URLRoomName);
          setRoomDisplayName(edu.Name + ' and ' + lea.Name);
          // setLoaded(true);
        });
      });
    }
  }, [URLRoomName]);

  useEffect(() => {
    if (URLParticipantUserID) {
      getUser(URLParticipantUserID).then((OIKOSuser: any) => {
        setOikosUser(OIKOSuser);
        setUserID(URLParticipantUserID);
      });
      // setStep(Steps.deviceSelectionStep);
    }
  }, [URLParticipantUserID]);

  useEffect(() => {
    if (oikosUser) {
      let count = educator;
      if (oikosUser.UserType === 'EDUCATOR') {
        count = learner;
      }
      setCounterpart(count);
      setLoaded(true);
    }
  }, [educator, learner, oikosUser]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  // const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
  //   // @ts-ignore
  //   if (!window.location.origin.includes('twil.io') && !window.STORYBOOK_ENV) {
  //     window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}${window.location.search || ''}`));
  //   }
  //   setStep(Steps.deviceSelectionStep);
  // };
  const handleSubmit = () => {
    setStep(Steps.deviceSelectionStep);
  };

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.roomNameStep && loaded && (
        <RoomNameScreen
          userID={userID}
          userName={oikosUser.Name}
          counterpartName={counterpart?.Name}
          roomName={roomName}
          roomDisplayName={roomDisplayName}
          // setRoomName={setRoomName}
          // setUserID={setUserID}
          handleSubmit={handleSubmit}
        />
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen
          userID={userID}
          userName={oikosUser.Name}
          roomName={roomName}
          roomDisplayName={roomDisplayName}
          setStep={setStep}
        />
      )}
    </IntroContainer>
  );
}
