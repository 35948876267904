// import axios from "axios";
// import {API_ENDPOINT} from "../config";
// const API_ENDPOINT = "http://localhost:8090";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://oikos.polis.ac';

export async function getUser(userID: string) {
  const params = {
    UserID: userID,
  };

  return fetch(API_ENDPOINT + '/users/get', {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }),
    body: JSON.stringify(params),
  })
    .then(response => response.json())
    .then(data => {
      return data.Payload;
    });
}
