import React from 'react';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme, Link } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface RoomNameScreenProps {
  userID: string;
  userName: string;
  counterpartName: string;
  roomName: string;
  roomDisplayName: string;
  handleSubmit: () => void;
}

export default function RoomNameScreen({
  userID,
  userName,
  counterpartName,
  roomName,
  roomDisplayName,
  handleSubmit,
}: RoomNameScreenProps) {
  const classes = useStyles();
  // const { user } = useAppState();

  return (
    <>
      <Typography variant="h4" className={classes.gutterBottom}>
        Hi {userName}!
      </Typography>

      <Typography variant="h5" className={classes.gutterBottom}>
        You're about to join your session with {counterpartName}.
      </Typography>

      <Typography variant="h5" className={classes.gutterBottom}>
        SESSION RECORDING
      </Typography>

      <Typography variant="body1" className={classes.gutterBottom}>
        As per our <Link href="https://www.polis.ac/terms-of-service">Terms of Service</Link>, Polis requires that all
        office hours sessions are recorded and transcribed for accessibility, quality-control, matching, and research
        purposes. Our <Link href="https://www.polis.ac/privacy">Privacy Policy</Link> outlines our management, storage,
        and deletion of this data.
      </Typography>

      <Typography variant="body1" className={classes.gutterBottom}>
        By clicking “Continue”, you re-affirm your consent to your session with {counterpartName} being recorded and
        transcribed by Polis.
      </Typography>

      <Typography variant="body1" className={classes.gutterBottom}>
        If you have any questions, please contact us at <Link href={'mailto:polis@polis.ac'}>polis@polis.ac</Link>
      </Typography>

      <Grid container justifyContent="flex-start">
        <Button
          onClick={handleSubmit}
          variant="contained"
          type="submit"
          color="primary"
          disabled={!userID || !roomName}
          className={classes.continueButton}
        >
          Continue
        </Button>
      </Grid>
    </>
  );
}
